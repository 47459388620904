
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}




body {
  margin: 0;
    
  font-family:'outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{
  font-size: 1.6rem;
}
h1{
  font-size: 6rem;
}

.App{
  width: 100%;
  height: 130vh;

  position: relative;
  background-color: rgba(0, 0,0, 0.4);
  color: #fff;
}

.App::before{
  content: '';
  background: url('./assests/sun\ rise.jpg')no-repeat center center/cover;
  position: absolute;
  width: 100%;
  height: 100%;
  
  top:0;
  left:0;
  z-index: -1;
}

.App .search{
  text-align: center;
  padding: 1rem;
  
}
.App input{
  padding: .7rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.1);
  color: #f8f8f8;
}

::placeholder{
  color: #f8f8f8;
}
.cointainer{
  max-width: 700px;
  height: 500px;
  margin: auto;
  padding: 0 1rem;
  position: relative;
  top: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App .top{
  width: 100%;
  margin: 10rem auto 5rem auto;

}



.App .description{
   position: relative;
   right: -90%;
   transform-origin: 0 0;
   transform : rotate(269deg);

}

.App .bottom{
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  height: fit-content;
  margin: 0.5rem auto 15rem auto;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
}

.bold{
  font-weight: 700;
}
